import React from 'react';
import $ from 'jquery';
import { Table } from 'semantic-ui-react';

import AddExpense from './add';
import Title from '../../product/add_product/title';
import server from '../../functions/server';
import Load from '../../load';
import View from './view';

export default class Expense extends React.Component {

  state = { load: false, expense: [] }

  componentDidMount() {
    this.getExpense();
  }

  getExpense() {
    let context = this;
    let url = server() + '/monthlyexpenses/master/getalldatabyshopid.php';
    let data = { id: this.props.data.id };
    $.ajax({
      url, data, type: 'POST',
      beforeSend: function(x) { context.setState({ load: true }); }
    }).done((data) => {
      let out = [];
      try {
        out = JSON.parse(data);
      } catch(e) {}
      context.setState({ load: false, expense: out });
    });
  }

  updateItem(type, id, name) {
    if (type == 'Update') {
      let data = this.state.expense;
      let newData = [];
      data.map((c) => { if (c.id == id) { c.status = name; } })
      this.setState({ expense: data })
    }
    else if (type == 'Delete') {
      let data = this.state.expense;
      let newData = [];
      data.map((c) => { if (c.id != id) { newData.push((c)); } })
      this.setState({ expense: newData })
    }
    else {
      let data = this.state.expense;
      data.push({ 'id': id, 'name': name, 'status': 1 })
      this.setState({ expense: data })
    }
  }

  render() {
    let i = 1;
    let x = (<div></div>);
    x = this.state.expense.map((c) => {
      return (
        <View item={c} sino={i++} updateItem={this.updateItem.bind(this)} />
      )
    })
    return (
      <div>
        <Load open={this.state.load} message='Getting Expenses' />
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Expense"} menu={true}/>
        <div style={{padding: '10%'}}>
          <Table celled selectable>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>SINO</Table.HeaderCell>
                <Table.HeaderCell width={5}>EXPENSE NAME</Table.HeaderCell>
                <Table.HeaderCell width={3}>STATUS</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {x}
            </Table.Body>
          </Table>
          <center><AddExpense updateItem={this.updateItem.bind(this)} shopId={this.props.data.id} /></center>
        </div>
      </div>
    );
  }
}
