import React from 'react';
import $ from 'jquery';

import Load from '../load';
import domain from '../functions/domain';
import server from '../functions/server';
import Title from '../product/add_product/title';
import { Button, Table, Form, Grid, Popup, TextArea, Dropdown } from 'semantic-ui-react';

import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Link } from 'react-router-dom';

export default class MonthlyExpenses extends React.Component {

    state = {
        date: this.getFormatedDate(new Date(), 0), //current date
        fromDate: this.getFormatedDate(new Date(), 7),
        report: [], data: {}, total: 0, master: []
    }

    getFormatedDate(dates, interval) {
        if (interval > 0) {
            dates = new Date(dates.getTime() - (interval * 24 * 60 * 60 * 1000));
        }
        let month = dates.getMonth() + 1; let date = dates.getDate();
        month = (month >= 10) ? month : "0" + month;
        date = (date >= 10) ? date : "0" + date;
        let x = dates.getFullYear() + "-" + month + "-" + date;
        return x;
    }

    componentDidMount() {
        this.getMonthlyExpensesReport();
        this.getMaster();
    }

    getMaster = () => {
        let context = this;
        let url = server() + "/monthlyexpenses/master/getalldatabyshopid.php";
        $.ajax({
            url, data: { 'id': this.props.data.id, }, type: 'POST',
            beforeSend: function(x) {
                context.setState({ load: true });
            }
        }).done((data) => {
            try {
                data = JSON.parse(data);
                context.setState({ master: data, load: false });
            } catch (error) {
                alert('Failed')
                context.setState({ load: false })
            }
        });
    }

    getMonthlyExpensesReport = () => {
        let context = this;
        let { fromDate, date } = this.state;
        let url = server() + "/monthlyexpenses/getalldatabyshopid.php";
        $.ajax({
            url, data: { 'id': this.props.data.id, date, 'from_date': fromDate }, type: 'POST',
            beforeSend: function(x) {
                context.setState({ load: true });
            }
        }).done((data) => {
            try {
                // data = data.trim();
                data = JSON.parse(data);
                let total = 0;
                data.map((v) => {
                    total = total + Number(v.amount);
                })
                context.setState({ report: data, load: false, total })
            } catch (error) {
                alert('Failed')
                context.setState({ load: false })
            }
        });
    }

    submitExpenses = () => {
        let context = this;
        let { data } = this.state;
        data['shop_id'] = this.props.data.id;
        if (isNaN(data.expense_id) || data.expense_id < 1) {
            alert("Invalid Expense Id");
            return;
        }
        if (!data.expense || data.expense.length < 1) {
            alert("Invalid Title");
            return;
        }
        if (isNaN(data.amount) || data.amount < 1) {
            alert("Invalid Amount");
            return;
        }
        if (!data.remarks || data.remarks.length < 1) {
            alert("Invalid Remarks");
            return;
        }
        if (!data.date) {
            alert("Invalid Date");
            return;
        }
        let url = server() + "/monthlyexpenses/add.php";
        if (data['id']) {
            url = server() + "/monthlyexpenses/edit.php";
        }
        $.ajax({
            url, data, type: 'POST',
            beforeSend: function(x) {
                context.setState({ load: true });
            }
        }).done((data) => {
            console.log(data);
            if (data == 1) {
                alert('Success');
                context.setState({ addExpensesDialog: false, load: false, data: {} })
                context.getMonthlyExpensesReport();
            }
            else {
                alert('Failed');
                context.setState({ load: false })
            }
        });
    }

    deleteExpense = (id) => {
        let context = this;
        let data = { id };
        let url = server() + "/monthlyexpenses/delete.php";
        $.ajax({
            url, data, type: 'POST',
            beforeSend: function(x) {
                context.setState({ load: true });
            }
        }).done((data) => {
            context.setState({ load: false })
            if (data == 1) {
                alert('Success');
                context.getMonthlyExpensesReport();
            }
            else {
                alert('Failed');
            }
        });
    }

    handleChange = (key) => (e, { value }) => {
        this.setState({
            data: {
                ...this.state.data,
                [key]: value
            }
        })
    }

    handleOnChangeDropDown = (e, { name, value }) => {
        this.setState({
            data: {
                ...this.state.data,
                'expense_id': value
            }
        })
    }

    render() {
        let { data, report, fromDate } = this.state;
        let i = 1;
        let expensesOption = this.state.master.map(m => ({key: m.id, value: m.id, text: m.name}))
        return (
            <div>
                <Load open={this.state.load}/>
                <Title shopDetails={this.props.data} menu={true} shopId={this.props.data.id} 
                    title={this.props.data.shop_name + " - Expenses"} /><br/>
                    <div style={{marginLeft: 10}}>
                        <Grid columns={8}>
                            <Grid.Row>
                                <Grid.Column>
                                    <TextField style={{marginLeft: '20%'}} label="From Date" type="date" defaultValue={fromDate}
                                        onChange={(e) => this.setState({fromDate: e.target.value})} />
                                </Grid.Column>
                                <Grid.Column>
                                    <TextField label="To Date" type="date" defaultValue={this.state.date} style={{marginLeft: 5}}
                                        onChange={(e) => this.setState({date: e.target.value})} />
                                </Grid.Column>
                                <Grid.Column>
                                    <Button primary onClick={this.getMonthlyExpensesReport}>Submit</Button>
                                </Grid.Column>
                                <Grid.Column>
                                    
                                </Grid.Column>
                                <Grid.Column>
                                    
                                </Grid.Column>
                                <Grid.Column>
                                    
                                </Grid.Column>
                                <Grid.Column>
                                    <b>{"Total Expenses: Rs. " + this.state.total}</b>
                                </Grid.Column>
                                <Grid.Column>
                                    <Button primary onClick={ () => { this.setState({ addExpensesDialog: true }) }}>Add Expenses</Button>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                        
                        
                        
                        
                        
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.HeaderCell>S.NO</Table.HeaderCell>
                                    <Table.HeaderCell>EXPENSE</Table.HeaderCell>
                                    <Table.HeaderCell>DATE</Table.HeaderCell>
                                    <Table.HeaderCell>AMOUNT</Table.HeaderCell>
                                    <Table.HeaderCell>CREATED ON</Table.HeaderCell>
                                    <Table.HeaderCell>DETAILS</Table.HeaderCell>
                                    <Table.HeaderCell>EDIT</Table.HeaderCell>
                                    <Table.HeaderCell>DELETE</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    report.map((item) => {
                                        return (
                                            <Table.Row>
                                                <Table.Cell>{i++}</Table.Cell>
                                                <Table.Cell>{item.name}</Table.Cell>
                                                <Table.Cell>{item.date}</Table.Cell>
                                                <Table.Cell>{item.amount}</Table.Cell>
                                                <Table.Cell>{item.created_on}</Table.Cell>
                                                <Table.Cell>
                                                    <Popup
                                                        content={item.expense + ' - ' + item.remarks}
                                                        trigger={<b style={{color: '#309BC3'}}>{item.expense}</b>} />
                                                </Table.Cell>
                                                <Table.Cell><u onClick={ () => { this.setState({ data: item, addExpensesDialog: true }) }}>Edit</u></Table.Cell>
                                                <Table.Cell>
                                                    <u style={{color: 'red', cursor: 'pointer'}} 
                                                        onClick={ () => this.deleteExpense(item.id)}>
                                                        Delete
                                                    </u>
                                                </Table.Cell>
                                            </Table.Row>
                                        )
                                    })
                                }
                            </Table.Body>
                        </Table>
                        <Dialog
                            fullWidth={true}
                            maxWidth={'md'}
                            open={this.state.addExpensesDialog}
                            onClose={() => this.setState({ addExpensesDialog: false })}
                            aria-labelledby="max-width-dialog-title"
                            >
                            <DialogTitle id="max-width-dialog-title">Add Expenses</DialogTitle>
                            <DialogContent>
                                <Grid columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                                <b>Expense</b>
                                                <Dropdown
                                                    placeholder='Expense'
                                                    fluid value={data.expense_id}
                                                    selection onChange={this.handleOnChangeDropDown}
                                                    options={expensesOption}
                                                />
                                                <div style={{ marginTop: 5 }}>
                                                    <Link to="/expenses" style={{ color: 'blue' }}>
                                                        Add more Expense &#8594;
                                                    </Link>
                                                </div>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid columns={3}>
                                    <Grid.Row>
                                        <Grid.Column>
                                                <b>Title</b>
                                                <Form.Input fluid placeholder={'Title'}
                                                    value={data.expense} 
                                                    onChange={this.handleChange("expense")}/>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <b>Date</b>
                                            <Form.Input fluid placeholder={'Date'} type="date"
                                                value={data.date} onChange={this.handleChange("date")}/>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <b>Amount</b>
                                            <Form.Input fluid placeholder={'Amount'}
                                                    value={data.amount} onChange={this.handleChange("amount")}/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                                <Grid columns={1}>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Form>
                                                <TextArea value={data.remarks} onChange={this.handleChange("remarks")} rows={2} 
                                                    placeholder='Remarks' />
                                            </Form>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </DialogContent>
                            <DialogActions>
                                <Button negative onClick={() => this.setState({ addExpensesDialog: false })}>Close</Button>
                                <Button positive onClick={this.submitExpenses}>Submit</Button>
                            </DialogActions>
                        </Dialog>
                    </div>
            </div>
        );
    }

}

