import React from 'react';
import Button from '@material-ui/core/Button';
import { Table, Rating } from 'semantic-ui-react';
import $ from 'jquery';

import Feedback from './feedback';
import server from '../functions/server';
import Title from '../product/add_product/title';

export default class Stocks extends React.Component {

  state = {
    order: [], feedbackvalue: 1, rating: 0
  }

  componentDidMount() {
    this.getOrderDetails();
  }

  getOrderDetails = () => {
    let context = this;
    var url_string = window.location.href;
    var urls = new URL(url_string);
    var id = urls.searchParams.get("o");
    let url = server() + '/orders/getorderdetails.php';
    let data = {id};
    $.ajax({
      url: url,
      data, type: 'POST',
      beforeSend: function(x) {
        context.setState({ load: true });
      }
    }).done((data) => {
      let out = 1;
      let feedbackvalue, feedback, rating;
      try {
        out = JSON.parse(data);
        out.map((c) => { feedback = c.feedback;  rating = c.rating; })
        if (feedback.length < 20) { feedbackvalue = 1; }
        else { feedbackvalue = 0; }
      } catch(e) {}
      rating = context.convertToNumber(rating);
      context.setState({ order: out, load: false, feedbackvalue, feedback, rating });
    });
  }

  convertToNumber(value) {
    try {
      value = Number(value);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  makeDecimalPoint(value) {
    try {
      value = value.toFixed(2);
    } catch (e) {
      value = 0;
    }
    return value;
  }

  feedBack() {
    if (this.state.feedbackvalue == 1) {
      this.setState({ openFeedBack: true })
    }
    else {
      alert('Already Feedback is Posted')
    }
  }

  updateValue(rating, feedback) {
    this.setState({ rating, feedbackvalue: 0, feedback, openFeedBack: false })
  }


  downloadPdf(size) {
    var url_string = window.location.href;
    var urls = new URL(url_string);
    var id = urls.searchParams.get("o");
    var shop_id = urls.searchParams.get("s");
    let url = server()+"/orders/viewbill/?s=" + shop_id + "&o=" + id;
    if (size === 'A5') {
      url += '&size=A5';
    }
    window.open(url, '_blank');
  }

  render() {
    let i = 1;
    var url_string = window.location.href;
    var urls = new URL(url_string);
    var id = urls.searchParams.get("o");
    let totalQty = 0, allPrice = 0, allTax = 0, allMRP = 0; 
    return (
      <div>
        <Title title={"Bill - Order Id: "+id} />
        <div style={{padding: '1%'}}>
          <Table width='100%'>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell style={{width: '3%'}}>#</Table.HeaderCell>
                <Table.HeaderCell>Product</Table.HeaderCell>
                <Table.HeaderCell><div style={{textAlign: 'right'}}>Rate</div></Table.HeaderCell>
                <Table.HeaderCell><div style={{textAlign: 'right'}}>Qty</div></Table.HeaderCell>
                <Table.HeaderCell><div style={{textAlign: 'right'}}>Tax</div></Table.HeaderCell>
                <Table.HeaderCell><div style={{textAlign: 'right'}}>Price</div></Table.HeaderCell>
                <Table.HeaderCell><div style={{textAlign: 'right'}}>Tax Price</div></Table.HeaderCell>
                <Table.HeaderCell><div style={{textAlign: 'right'}}>Total Price</div></Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                this.state.order.map((c) => {
                  let price = this.convertToNumber(c.price);
                  let tax = this.convertToNumber(c.tax);
                  let quantity = this.convertToNumber(c.quantity);
                  let totalPrice = price * quantity;
                  let taxAmount = ((totalPrice * tax)/100);
                  let totalAmount = totalPrice + taxAmount;
                  totalQty += quantity;
                  allPrice += totalPrice;
                  allTax += taxAmount;
                  allMRP += totalAmount;
                  return (
                    <Table.Row>
                      <Table.Cell>{i++}</Table.Cell>
                      <Table.Cell>{c.product_name}</Table.Cell>
                      <Table.Cell><div style={{textAlign: 'right'}}>{this.makeDecimalPoint(price)}</div></Table.Cell>
                      <Table.Cell><div style={{textAlign: 'right'}}>{c.quantity}</div></Table.Cell>
                      <Table.Cell><div style={{textAlign: 'right'}}>{c.tax}</div></Table.Cell>
                      <Table.Cell><div style={{textAlign: 'right'}}>{this.makeDecimalPoint(price * quantity)}</div></Table.Cell>
                      <Table.Cell><div style={{textAlign: 'right'}}>{this.makeDecimalPoint(taxAmount)}</div></Table.Cell>
                      <Table.Cell><div style={{textAlign: 'right'}}>{this.makeDecimalPoint(totalAmount)}</div></Table.Cell>
                    </Table.Row>
                  )
                })
              }
              <Table.Row>
                <Table.Cell></Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell style={{textAlign: 'right'}}><b>Total</b></Table.Cell>
                <Table.Cell style={{textAlign: 'right'}}>{totalQty}</Table.Cell>
                <Table.Cell></Table.Cell>
                <Table.Cell style={{textAlign: 'right'}}>{'Rs. ' + allPrice}</Table.Cell>
                <Table.Cell style={{textAlign: 'right'}}>{'Rs. ' + allTax}</Table.Cell>
                <Table.Cell style={{textAlign: 'right'}}>{'Rs. ' + allMRP}</Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          { this.state.feedbackvalue == 0 ?
            (
              <div>
                <b>Rating  :   </b>
                <b style={{color: 'green'}}>({this.state.rating}/5)</b><br/><br/>
                <b style={{color: 'red'}}>Feedback</b><br/>
                <b style={{padding: '2%'}}>{this.state.feedback}</b>
              </div>
            ):''
          }
        </div>
        <center>
          <Button color="primary" onClick={this.feedBack.bind(this)} variant="contained">Feedback</Button>
          <Button color="secondary" onClick={this.downloadPdf.bind(this)} style={{marginLeft: 20}} variant="contained">Download PDF</Button>
          <Button color="secondary" onClick={this.downloadPdf.bind(this, 'A5')} style={{marginLeft: 20}} variant="contained">Download A5</Button>
        </center>
        <Feedback openFeedBack={this.state.openFeedBack} close={ () => { this.setState({ openFeedBack: false }) }} update={this.updateValue.bind(this)} orderId={id} />
      </div>
    );
  }

}
