import React, { createRef } from 'react';
import { Button } from 'semantic-ui-react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Title from '../product/add_product/title';
import { Grid } from 'semantic-ui-react';

import Load from '../load';
import PurchaseReport from './purchase';
import SalesReport from './sales';
import Prime from '../prime';
import StockReport from './stockreport';
import GrossProfitReport from './grossprofit';

export default class Billing extends React.Component {

  state = {
    load: false,
  }

  sales = createRef();
  purchase = createRef();

  setLoad = (load) => this.setState({ load });

  render() {

    return (
      <div>
        <Load open={this.state.load}/>
        <Title shopDetails={this.props.data} shopId={this.props.data.id} title={this.props.data.shop_name + " - Reports"} menu={true}/>
        { this.props.data.prime == 0 ?
          (
            <div><Prime data={this.props.data}/></div>
          ):
          (
            <Grid style={{paddingTop: '1.5%', paddingLeft: '1%', paddingRight: '1%'}}>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Paper>
                  <Typography variant="h5" component="h3" style={{padding: '1%', color: '#2261A1'}}>
                    Sales
                  </Typography>
                  <SalesReport 
                    ref={this.sales}
                    data={this.props.data} 
                    load={this.state.load}
                    setLoad={this.setLoad}/>
                </Paper>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <Paper>
                  <Typography variant="h5" component="h3" style={{padding: '1%', color: '#2261A1'}}>
                    Purchase
                  </Typography>
                  <PurchaseReport 
                    ref={this.purchase}
                    data={this.props.data} 
                    load={this.state.load}
                    setLoad={this.setLoad}/>
                </Paper>
              </Grid.Column>
              <Grid.Column mobile={16} tablet={16} computer={16}>
                <center style={{display: 'flex', marginLeft: '38%'}}>
                  <StockReport data={this.props.data} />
                  <GrossProfitReport data={this.props.data} />
                  <Button color='blue' onClick={() => {
                      this.sales.current.getDates();
                      this.purchase.current.getDates();
                    }}>
                    Refresh
                  </Button>
                </center>
              </Grid.Column>
            </Grid>
          )
        }
      </div>
    );
  }

}
