import React, { Component } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import { Image } from 'semantic-ui-react';
import 'semantic-ui-css/semantic.min.css';
import blue from '@material-ui/core/colors/blue';
import $ from 'jquery';

import Shop from './shop';
import New from './shop/new';
import Intro from './intro';
import domain from './functions/domain';
import server from './functions/server';
import userId from './functions/userid';
import './App.css';
import Back from './media/background.jpg';
import Edit from './product/edit_product/edit_product';
import Add from './product/add_product/addproduct';
import ViewBill from './viewbill/';
import Package from './prime/package.jsx';
import Admin from './admin';

const theme = createMuiTheme({
  palette: {
    primary: blue,
  }
});

// eslint-disable-next-line no-extend-native
Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});

class App extends Component {

  constructor() {
    super();
    this.state = { load: false }
  }

  componentDidMount() {
    if (window.location.pathname.includes("/bill") && !window.location.pathname.includes("/billing")) {
      return;
    }
    let shop = this.getShopName();
    console.log(shop)
    let others = ['stocks', 'billing', 'billing/new', 'customers/duedate', 'purchases', 'customers', 'statements', 'reports', 'gst-report', 'settings',
                  'mapping', 'sms', 'order', 'pns-service', 'prime', 'ledger-transaction', 'ledger', 'category-alignment', 'social-media', 'employees',
                  'employees/designations', 'employees/attendance', 'employees/accounts', 'employees/holidays',
                  'purchases/new', 'expenses',
                  'feedback-report', 'bill', 'product-enquiry', 'package', 'logistics-billing', 'bank-details', 'secondary-category', 'master-category'];
    if (others.includes(shop)) {
      this.getShop(userId()); return;
    }
    if (shop) {
      let x = window.location.href; var url = new URL(x);
      var edit = url.searchParams.get("from");
      var add = url.searchParams.get("shop");
      let urlss = window.location.href;
      urlss = urlss.split('/')[3];
      if (urlss == 'admin') { this.setState({ value: urlss, out: 'admin' }); return; }
      if (edit) { this.setState({ value: x, out: 'edit' }); return; }
      if (add) { this.setState({ value: x, out: 'add', shopId: add }); return; }
      this.getShop(shop, true); return;
    }
    let id = userId();
    if (id == null) {
      this.setState({ out: -100 });
      return (<div></div>);
    } else {
      this.getShop(id);
    }
  }

  getShopName() {
    try {
      let x = window.location.href;
      let d = x.split(domain())[1];
      if (d.split('?')[0] == 'bill') { return 'bill'; }
      if (d.length < 2) { return null; }
      return d;
    } catch(e) {
      return null;
    }
  }

  getShopDetails() {
    this.getShop(userId());
  }

  getTodayDate() {
    var today = new Date();
    var dd = today.getDate();
    var mm = today.getMonth() + 1;
    var yyyy = today.getFullYear();
    if(dd < 10) { dd = '0' + dd; }
    if(mm < 10) { mm = '0' + mm; }
    today = yyyy+'-'+mm+'-'+dd;
    return today;
  }

  getShop(id, isName) {
    let context = this;
    let prime = 1; let date = this.getTodayDate();
    let url = server() + '/shop/getshopbyid.php?id='+id+'&date='+date;
    if (isName) {
      id = id.split("-"); id = id.join(" ");
      url = server() + '/shop/getshopbyname.php?name='+id+'&date='+date;
    }
    console.log(url);
    $.ajax({
      url: url, beforeSend: function(x) { context.setState({ load: true }); }
    }).done((data) => {
      let out = 1;
      try {
        out = JSON.parse(data);
        let todayDate = new Date(date);
        let tillDate = new Date(out.till_date);
        if(todayDate < tillDate){
        } else { out.prime = 0; prime = 0; }
        prime = Number(out.prime);
        if (prime == 0) { out.primeLimitation = 10; }
        if (prime == 1) { out.primeLimitation = 50; }
        if (prime == 2) { out.primeLimitation = 100; }
        if (prime == 3) { out.primeLimitation = 100000000; }
        if (prime == 4) { out.primeLimitation = 100000000; }
        if (isName) { out['view'] = true; }
      } catch(e) {}
      context.setState({ out: out, load: false, prime });
    });
  }

  render() {
    if (window.location.pathname.includes("/bill") && !window.location.pathname.includes("/billing")) {
      return (
        <Router>
          <Switch>
            <Route exact path="/bill" component={() => <ViewBill />} />
          </Switch>
        </Router>
      );
    }
    let x = (<div><LinearProgress /></div>);
    if (this.state.out === 1) { 
      x = (<New />);
    }
    else if (typeof this.state.out === 'object') { x = (<Shop data={this.state.out} getShopDetails={this.getShopDetails.bind(this)} />); }
    else if (this.state.out === 'edit') { x = (<Edit />); }
    else if (this.state.out === 'add') { x = (<Add shop={this.state.shopId} from='' />); }
    else if (this.state.out === 'admin') { x = (<Admin />); }
    if (this.state.prime == 0) { x = (<Package data={this.state.out} />); }
    return (
      <MuiThemeProvider theme={theme}>{x}</MuiThemeProvider>
    );
  }
}

export default (App);
