import React, { Component } from 'react';
import { Card, Grid, Icon, Checkbox } from 'semantic-ui-react';
import $ from "jquery";
import server from '../../functions/server';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { DialogActions, DialogContent, DialogTitle } from '@material-ui/core';


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


class View extends Component {

  state = {
    orders: []
  }

  viewOrderDetails() {
    let context = this;
    $.ajax({
      type: 'POST',
      data: { 'id': context.props.id },
      url: server() + '/order/viewdetailorders.php',
      success: function(orders)
      {
        console.log(orders);
        orders = JSON.parse(orders);
        console.log(orders);
        context.setState({
          loading: false,
          orders: orders,
          open: true
        });
      },
      error: function(err)
      {
      }
    });
  }

  changeStatus(status, id) {
    let context = this;
    let data1 = { 'status': status, 'id': id };
    context.setState({ loading: true });
    $.ajax({
      type: 'POST',
      data: data1,
      url: server() + '/order/updatestatus.php',
      success: function(orders)
      {
        console.log(orders);
        if (orders == 1) {
          context.updateOrders(status, id);
        }
        else {
          alert('Error')
        }
        context.setState({
          loading: false,
        });
      },
      error: function(err)
      {
      }
    });
  }

  updateOrders(status, id) {
    let order = this.state.orders;
    for (var i = 0; i < order.length; i++) {
      if (order[i].id == id) {
        order[i].status = status;
      }
    }
    this.setState({ orders: order })
    this.props.updateOrders();
  }

  status(status, id) {
    let radio = (<div></div>);
    if (status == 1) {
      radio = (
        <div>
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Accept' onClick={this.changeStatus.bind(this, 2, id)} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Packing' disabled onClick={this.changeStatus.bind(this, 3, id)} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Shipping' disabled onClick={this.changeStatus.bind(this, 4, id)} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Delivered' disabled onClick={this.changeStatus.bind(this, 5, id)} />
            </Grid.Column>
          </Grid>
        </div>
      );
    }
    if (status == 2) {
      radio = (
        <div>
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Accept' checked />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Packing' onClick={this.changeStatus.bind(this, 3, id)} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Shipping' onClick={this.changeStatus.bind(this, 4, id)} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Delivered' onClick={this.changeStatus.bind(this, 5, id)} />
            </Grid.Column>
          </Grid>
        </div>
      );
    }
    if (status == 3) {
      radio = (
        <div>
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Accept' checked />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Packing' checked />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Shipping' onClick={this.changeStatus.bind(this, 4, id)} />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Delivered' onClick={this.changeStatus.bind(this, 5, id)} />
            </Grid.Column>
          </Grid>
        </div>
      );
    }
    if (status == 4) {
      radio = (
        <div>
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Accept' checked />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Packing' checked />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Shipping' checked />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Delivered' onClick={this.changeStatus.bind(this, 5, id)} />
            </Grid.Column>
          </Grid>
        </div>
      );
    }
    if (status == 5) {
      radio = (
        <div>
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Accept' checked />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Packing' checked />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Shipping' checked />
            </Grid.Column>
            <Grid.Column mobile={16} tablet={16} computer={2}>
              <Checkbox radio label='Delivered' checked />
            </Grid.Column>
          </Grid>
        </div>
      );
    }
    if (status == 0) {
      radio = (
        <div>
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div style={{color: 'red'}}>This order is cancelled!!!</div>
            </Grid.Column>
          </Grid>
        </div>
      );
    }
    if (status == -1) {
      radio = (
        <div>
          <Grid>
            <Grid.Column mobile={16} tablet={16} computer={16}>
              <div style={{color: 'red'}}>This order is cancelled!!!</div>
            </Grid.Column>
          </Grid>
        </div>
      );
    }
    return radio;
  }

  cancelOrder = () => {
    let context = this;
    let data = { 'id': this.props.id };
    context.setState({ loading: true });
    $.ajax({
      type: 'POST',
      data,
      url: server() + '/order/cancel.php',
      success: function(data) {
        if (data == "1") {
          alert("Order Deleted SuccessFully")
          context.props.updateOrders();
          context.setState({ cancelOrderConfirmation: false })
        }
        else {
          alert("Failed To Cancel Order");
        }
      },
      error: function(err) {
      }
    });
  }

  render() {
    let type = this.props.type;
    let order = this.state.orders;
    let color = 'red';
    return (
      <div style={{paddingLeft: '2%', paddingRight: '2%'}}>
        { type == 'pickup' ?
          (
            <Card fluid onClick={this.viewOrderDetails.bind(this)}>
              <Card.Content>
                <Card.Header>
                  <Grid>
                    <Grid.Column mobile={16} table={16} computer={14}>
                      {this.props.customer_name+'('+this.props.customer_mobile+')'}
                    </Grid.Column>
                    <Grid.Column mobile={16} table={16} computer={2}>
                      <div style={{color: color, fontSize: 14}}>{type}</div>
                    </Grid.Column>
                  </Grid>
                </Card.Header>
              </Card.Content>
              <Card.Content>
                <Grid>
                  <Grid.Column mobile={8} tablet={8} computer={11}>
                    <h4>Order Id : {this.props.id}</h4>
                    Items : {this.props.items}
                    <h4>Price : {Number(this.props.price).toFixed(2)}</h4>
                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={8} computer={5}>
                    <b>Pickup</b><br/>
                    Ordered Date & Time: {this.props.orderon.split(' ')[0]+' & '+this.props.orderon.split(' ')[1]}<br/>
                    Pickup Date & Time:  {this.props.pickuptime.split(' ')[0]+' & '+this.props.pickuptime.split(' ')[1]}
                  </Grid.Column>
                </Grid>
              </Card.Content>
              <Card.Content>
                <Grid>
                  <Grid.Column mobile={16} tablet={16} computer={3}>
                    {'Not Accepted: '+this.props.notaccept}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={3}>
                    {'Accepted: '+this.props.Accept}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={3}>
                    {'Packed: '+this.props.Packing}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={3}>
                    {'Shipped: '+this.props.Shipping}
                  </Grid.Column>
                  <Grid.Column mobile={16} tablet={16} computer={3}>
                    {'Delivered: '+this.props.Delivered}
                  </Grid.Column>
                </Grid>
              </Card.Content>
            </Card>
          ) :
          (
            <Card fluid onClick={this.viewOrderDetails.bind(this)}>
              <Card.Content>
                <Card.Header>
                  <Grid>
                    <Grid.Column mobile={16} table={16} computer={14}>
                      {this.props.customer_name+'('+this.props.customer_mobile+')'}
                    </Grid.Column>
                    <Grid.Column mobile={16} table={16} computer={2}>
                      <div style={{color: color, fontSize: 14}}>{type}</div>
                    </Grid.Column>
                  </Grid>
                </Card.Header>
              </Card.Content>
              <Card.Content>
                <Grid>
                  <Grid.Column mobile={8} tablet={8} computer={11}>
                      <h4>Order Id : {this.props.id}</h4>
                      Items : {this.props.items}
                      <h4>Price : {Number(this.props.price).toFixed(2)}</h4>
                      Ordered Date & Time: {this.props.orderon.split(' ')[0]+' & '+this.props.orderon.split(' ')[1]}<br/>

                  </Grid.Column>
                  <Grid.Column mobile={8} tablet={8} computer={5}>
                    { type == 'delivery' ?
                      (
                        <div>
                        <b style={{fontSize: '15px'}}>Delivery address</b>
                        <div style={{padding: '2%'}}>
                          <b style={{color: '#0D7DA2', fontSize: '17px' }}>{this.props.customer_name}</b><br/>
                          {this.props.customer_address},<br/>
                          <b>Phone number: {this.props.customer_mobile}</b>
                        </div>
                        </div>
                      ):
                      (
                        <div></div>
                      )
                    }
                  </Grid.Column>
                </Grid>
              </Card.Content>
              <Card.Content>
              <Grid>
                <Grid.Column mobile={16} tablet={16} computer={3}>
                  {'Not Accept: '+this.props.notaccept}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={3}>
                  {'Accept: '+this.props.Accept}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={3}>
                  {'Packing: '+this.props.Packing}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={3}>
                  {'Shipping: '+this.props.Shipping}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={2}>
                  {'Delivered: '+this.props.Delivered}
                </Grid.Column>
                <Grid.Column mobile={16} tablet={16} computer={2}>
                  <div style={{ textAlign: "end", color: "red"}}>Cancel Order</div>
                </Grid.Column>
              </Grid>
              </Card.Content>
            </Card>
          )
        }
        <Dialog fullScreen open={this.state.open} onClose={ () => { this.setState({ open: false }) }} TransitionComponent={Transition}>
        <AppBar>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={ () => { this.setState({ open: false }) }} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography variant="h6">
              <div style={{color: 'white'}}>Products</div>
            </Typography>
          </Toolbar>
        </AppBar>
        <br/><br/><br/><br/>
        <div style={{padding: '1%'}}>
          {
            order.map((x) => {
              return (
                <Card fluid>
                  <Card.Content>
                    <Card.Header>
                      {x.product_name}
                    </Card.Header>
                  </Card.Content>
                  <Card.Content>
                    <Grid>
                      <Grid.Column mobile={8} tablet={8} computer={3}>
                        Quantity: {x.quantity}
                      </Grid.Column>
                      <Grid.Column mobile={8} tablet={8} computer={3}>
                        Price: {Number(x.total_price).toFixed(2)}
                      </Grid.Column>
                    </Grid>
                  </Card.Content>
                  <Card.Content>
                    {this.status(x.status, x.id)}
                  </Card.Content>
                </Card>
              )
            })
          }
          <center><Button onClick={ () => this.setState({ cancelOrderConfirmation: true }) } style={{ color: 'red' }}>Cancel order</Button></center>
        </div>
        <Dialog fullWidth="md" open={this.state.cancelOrderConfirmation} 
          onClose={ () => { this.setState({ cancelOrderConfirmation: false }) }}>
            <DialogTitle>Confirmation</DialogTitle>
            <DialogContent>
              Are you sure to cancel this order?
            </DialogContent>
            <DialogActions>
              <Button onClick={ () => { this.setState({ cancelOrderConfirmation: false }) }}>No</Button>
              <Button onClick={ () => this.cancelOrder() }>Yes</Button>
            </DialogActions>
        </Dialog>
      </Dialog>
      </div>
    );
  }
}

export default View;
