import React from 'react';

import Load from '../../load';
import { Table, Dropdown } from 'semantic-ui-react';

export default class Expense extends React.Component {

  state = {
    load: false, expense: []
  }

  handleChange1 = (e, { value }) => {
    if (value == 'Active') { value = 1; }
    else { value = 0; }
  }


  render() {
    let status = this.props.item.status;
    if (this.props.item.status == '1' || this.props.item.status == 1) {
      status = 'Active';
    }
    else {
      status = 'Deactive';
    }
    let Options = [
      { key: 'Active', text: 'Active', value: 'Active' },
      { key: 'Deactive', text: 'Deactive', value: 'Deactive' }
    ]
    return (
      <Table.Row>
        <Table.Cell><Load open={this.state.load} />{this.props.sino}</Table.Cell>
        <Table.Cell>{this.props.item.name}</Table.Cell>
        <Table.Cell>
          <Dropdown placeholder='Select Expense' search selection
            fluid
            onChange={this.handleChange1} value={status}
            options={Options} />
        </Table.Cell>
      </Table.Row>
    );
  }

}
