import React from "react";
import $ from "jquery";
import server from "../functions/server";
import { Button } from "semantic-ui-react";

import { Add } from "@material-ui/icons/";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const add = () => {
    if (!category.length) {
      alert("Enter Category Name!!!");
      return;
    }
    $.ajax({
      type: "POST",
      url: server() + "/categories/addmaster.php",
      data: { category, shop_id: props.shopId },
      success: (data) => {
        console.log(data);
        if (data === "1") {
          setOpen(false);
          props.getMasterCategories();
        } else {
          alert("Duplicate Entry OR Failed");
        }
      },
      error: (e) => {
        console.log(e);
        alert("Error");
      },
    });
  };

  return (
    <div>
      <Button
        primary
        style={{ float: 'right', marginBottom: 10 }}
        variant="contained"
        color="success"
        onClick={handleClickOpen}
        startIcon={<Add />}
      >
        Add Master Category
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Master Category</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Add Master Category to use in Stock.
          </DialogContentText>
          <TextField
            autoFocus
            value={category}
            margin="dense"
            id="category"
            label="Category"
            type="name"
            onChange={(e) => setCategory(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="danger">
            Cancel
          </Button>
          <Button onClick={add} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
