import React from "react";
import $ from "jquery";
import { Table, Button, Icon, Input } from "semantic-ui-react";

import server from "../../functions/server";
import userId from "../../functions/userid";
import Load from "../../load";
import Item from "./item";
import Add from "../add";
import UpdateonlineProducts from "./updateonlineproducts";
import Export from "./export";

export default class View extends React.Component {
  state = {
    search: "",
    TodaysAddedStock: 0,
    toDay: this.getFormatedDate(new Date()),
  };

  getFormatedDate(dates, interval) {
    if (interval > 0) {
      dates = new Date(dates.getTime() - interval * 24 * 60 * 60 * 1000);
    }
    let month = dates.getMonth() + 1;
    let date = dates.getDate();
    month = month > 10 ? month : "0" + month;
    date = date > 10 ? date : "0" + date;
    let x = dates.getFullYear() + "-" + month + "-" + date;
    return x;
  }

  componentDidMount() {
    this.getAllStocks();
  }

  getAllStocks() {
    let context = this;
    let url = server() + "/stocks/getallstock.php";
    let data = { id: this.props.data.id, toDay: this.state.toDay };
    console.log(data);
    console.log(url);
    $.ajax({
      url: url,
      data,
      type: "POST",
      beforeSend: function (x) {
        context.setState({ load: true });
      },
    }).done((data) => {
      console.log(data);
      let TodaysAddedStock;
      let out = 1;
      try {
        out = JSON.parse(data);
        out.map((c) => {
          TodaysAddedStock = c.TodaysAddedStock;
        });
        if (!TodaysAddedStock) {
          TodaysAddedStock = 0;
        } else {
          TodaysAddedStock = Number(TodaysAddedStock);
        }
      } catch (e) {
        TodaysAddedStock = 0;
      }
      context.setState({ out: out, load: false, TodaysAddedStock });
    });
  }

  updateItem = (data) => {
    let out = this.state.out;
    let newOut = [];
    for (var i = 0; i < out.length; i++) {
      let x = out[i];
      if (x.id === data.id) {
        x.item_number = data.item_number;
        x.product_name = data.name;
        x.local_name = data.local_name;
        x.no_stock = data.stock;
        x.prize = data.price;
        x.tax = data.tax;
        x.dnp = data.dnp;
        x.min_stock = data.min_stock;
        x.publish = data.publish;
      }
      newOut.push(x);
    }
    this.setState({ out: newOut });
  };

  upload = (e) => {
    document.getElementById("outlined-button-file").click();
  };

  importData = (e) => {
    let context = this;
    let data = context.props.data;
    let file = e.target.files[0];
    if (!file) {
      return;
    }
    context.setState({ load: true, message: "File Uploading!!!" });
    var formData = new FormData();
    formData.append("file", file);
    formData.append("shop_id", data.id);
    $.ajax({
      type: "POST",
      processData: false,
      contentType: false,
      data: formData,
      url: server() + "/stocks/import.php",
      success: function (data) {
        console.log(data);
        context.setState({ load: false });
        if (data == 1) {
          alert("Success");
        } else {
          alert("Failed");
        }
      },
      error: function (error) {
        context.setState({ load: false });
      },
    });
  };

  getItemidForUpdateOnlineProducts = (id, value) => {
    this.refs.child.updateItems(id, value);
  };

  render() {
    let x = <div></div>;
    if (this.state.out && this.state.out !== 1) {
      let context = this;
      let search = this.state.search;
      let count = 0;
      x = this.state.out.map((temp) => {
        if (search) {
          search = search.toLowerCase();
          if (
            (temp.item_number &&
              temp.item_number.toLowerCase().includes(search)) ||
            (temp.product_name &&
              temp.product_name.toLowerCase().includes(search)) ||
            (temp.local_name && temp.local_name.toLowerCase().includes(search))
          ) {
            return (
              <Item
                data={context.props.data}
                item={temp}
                updateItem={context.updateItem}
                id={temp.id}
                out={this.state.out}
                getItemidForUpdateOnlineProducts={
                  this.getItemidForUpdateOnlineProducts
                }
                count={++count}
              />
            );
          }
        } else {
          return (
            <Item
              data={context.props.data}
              item={temp}
              id={temp.id}
              out={this.state.out}
              getItemidForUpdateOnlineProducts={
                this.getItemidForUpdateOnlineProducts
              }
              updateItem={context.updateItem}
              count={++count}
            />
          );
        }
      });
    }
    return (
      <div>
        <div style={{ margin: 10 }}>
          <div style={{ display: "flex" }}>
            <Input
              placeholder="Search..."
              value={this.state.search}
              onChange={(e, { value }) => {
                this.setState({ search: value });
              }}
            />
            <Button
              icon
              negative
              style={{ marginLeft: 5 }}
              onClick={this.getAllStocks.bind(this)}
            >
              <Icon name="undo" />
            </Button>
            <UpdateonlineProducts
              ref="child"
              out={this.state.out}
              shopId={this.props.data.id}
            />
            <Add
              getAllStocks={this.getAllStocks.bind(this)}
              out={this.state.out}
              TodaysAddedStock={this.state.TodaysAddedStock}
              data={this.props.data}
            />
            <Export id={this.props.data.id} toDay={this.state.toDay} />
            <div>
              <input
                onChange={this.importData}
                accept=".csv"
                id="outlined-button-file"
                type="file"
              />
            </div>
          </div>
        </div>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>S.No</Table.HeaderCell>
              <Table.HeaderCell>Item Code</Table.HeaderCell>
              <Table.HeaderCell>Part Name</Table.HeaderCell>
              <Table.HeaderCell>Stock</Table.HeaderCell>
              <Table.HeaderCell>Min Stock</Table.HeaderCell>
              <Table.HeaderCell>Dnp</Table.HeaderCell>
              <Table.HeaderCell>Price</Table.HeaderCell>
              <Table.HeaderCell>Tax</Table.HeaderCell>
              <Table.HeaderCell>Website</Table.HeaderCell>
              {/* <Table.HeaderCell>Image</Table.HeaderCell> */}
              <Table.HeaderCell>Edit</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{x}</Table.Body>
        </Table>
        <Load open={this.state.load} />
      </div>
    );
  }
}
