import React from "react";
import $ from "jquery";
import { Button } from "semantic-ui-react";
import { Add } from "@material-ui/icons/";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

import server from "../functions/server";
import Validation from "../Others/Validation";

export default function FormDialog(props) {
  const [open, setOpen] = React.useState(false);
  const [openC, setOpenC] = React.useState(false);
  const [masterCategory, setMasterCategory] = React.useState(0);
  const [category, setCategory] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const add = () => {
    let context = this;
    if (!props.choosedCategory) {
      alert("Select MasterCategory");
      return;
    }
    if (category.trim().length < 0) {
      return;
    }
    let data = { mid: props.choosedCategory, category, shopId: props.shopId };
    let { value, error } = Validation.AddSecoundaryCategory.validate(data);
    if (error) {
      alert(error);
    } else {
      $.ajax({
        type: "POST",
        url: server() + "/categories/add.php",
        data: data,
        success: (data) => {
          console.log(data);
          if (data === "1") {
            setOpen(false);
            props.refresh(props.choosedCategory);
          } else {
            alert("Duplicate Entry Or Failed");
          }
        },
        error: (e) => {
          console.log(e);
          alert("Error");
        },
      });
    }
  };

  return (
    <span>
      {props.choosedCategory > 0 && (
        <Button
          style={{ marginLeft: 5 }}
          primary
          variant="contained"
          color="success"
          onClick={handleClickOpen}
          startIcon={<Add />}
        >
          Add Secondary Category
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Add Category</DialogTitle>
        <DialogContent>
          <DialogContentText>Add Category to use in Stock.</DialogContentText>
          <TextField
            autoFocus
            value={category}
            margin="dense"
            id="category"
            label="Category"
            type="name"
            onChange={(e) => setCategory(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="danger">
            Cancel
          </Button>
          <Button onClick={add} color="primary">
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </span>
  );
}
